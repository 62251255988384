<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="page-title">
                Edit Script of {{ this.category_name }}
            </h3>
            <button type="button" class="btn btn-info believe-btn" @click="goBack()">
                <i class="mdi mdi-keyboard-backspace"></i> Back</button>

            <!-- <router-link class="btn btn-primary believe-btn" :to="`/hypnosis/list/${current_category_id}`">
            <i class="mdi mdi-keyboard-backspace"></i> Back</router-link> -->
            <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">hypn</a></li>
          <li class="breadcrumb-item active" aria-current="page">Basic</li>
        </ol>
      </nav> -->
        </div>
        <div class="row">
            <div class="col-md-8 m-auto d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <!-- <h4 class="card-title">Default form</h4>
                                <p class="card-description">
                                    Basic form layout
                                </p> -->
                                <b-form @submit.stop.prevent="onSubmit">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Title"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.name.$model"
                                                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>

                                                    <div v-if="$v.form.name.$error" class="invalid-feedback">
                                                    <div v-if="!$v.form.name.required">Title is required</div>
                                                    <div v-if="!$v.form.name.maxLength">Maximum limit 100
                                                        characters</div>
                                                </div>

                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">

                                            <b-form-group id="example-input-group-1" label="Author"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.author.$model"
                                                    :state="$v.form.author.$dirty ? !$v.form.author.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>

                                                    <div v-if="$v.form.author.$error" class="invalid-feedback">
                                                    <div v-if="!$v.form.author.required">Author is required</div>
                                                    <div v-if="!$v.form.author.maxLength">Maximum limit 30
                                                        characters</div>
                                                </div>
                                            </b-form-group>
                                        </div>
                                    </div>



                                    <div class="container-fluid my-4">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="file-box row" @click="$refs.image.click()">
                                                    <div class="col-md-1">
                                                        <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                        <input class="ml" type="file" 
                                                            @change="onAudioImageChange" ref="image"
                                                            style="display: none" />
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="file-text-box">
                                                            <span>Upload</span><br />
                                                            <small>Upload Cover Image Here</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img v-if="image_url !== ''" :src="image_url"
                                                            class="img-thumbnail rounded-circle" alt="Image"
                                                            style="height: 65px; width: 65px; margin-top:8px" />
                                                    </div>
                                                </div>
                                                <p class="err" v-if="image_error != ''">{{ image_error }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <b-form-group id="example-input-group-1" label="Play Screen Image"
                                        label-for="example-input-1">
                                        <img v-if="image_url == ''" :src="'/assets/base.jpg'"
                                            class="img-thumbnail rounded-circle" alt="Image"
                                            style="height: 65px; width: 65px" />
                                        <img v-if="image_url !== ''" :src="image_url"
                                            class="img-thumbnail rounded-circle" alt="Image"
                                            style="height: 65px; width: 65px" />
                                        <i class="fa fa-camera" @click="$refs.image.click()"
                                            style="font-size: 25px;"></i>
                                        <div class="clear"></div>
                                        <p class="err" v-if="image_error != ''">{{ image_error }}</p>
                                        <input class="ml" type="file"  @change="onAudioImageChange"
                                            ref="image" style="display: none" />
                                    </b-form-group>

                                    <b-form-group id="example-input-group-1" label="Cover Image"
                                        label-for="example-input-1">
                                        <img v-if="image_url2 == ''" :src="'/assets/base.jpg'"
                                            class="img-thumbnail rounded-circle" alt="Image"
                                            style="height: 65px; width: 65px" />
                                        <img v-if="image_url2 !== ''" :src="image_url2"
                                            class="img-thumbnail rounded-circle" alt="Image"
                                            style="height: 65px; width: 65px" />
                                        <i class="fa fa-camera" @click="$refs.cover_image.click()"
                                            style="font-size: 25px;"></i>
                                        <div class="clear"></div>
                                        <p class="err" v-if="image_error2 != ''">{{ image_error2 }}</p>
                                        <input class="ml" type="file"  @change="onAudioImageChange2"
                                            ref="cover_image" style="display: none" />
                                    </b-form-group>

                                    <b-form-group id="example-input-group-1" label="Audio" label-for="example-input-1">
                                        <a href="javascript:;" class="btn btn-primary" @click="$refs.audioFile.click()">
                                            <i class="mdi mdi-upload"></i> Upload Audio</a>
                                        <AudioFile :key="count" :audioSource="audioFile" />
                                        <div class="clear"></div>
                                        <input class="ml" type="file" 
                                            @change="uploadAudio" ref="audioFile" style="display: none" />
                                        <p class="err" v-if="audioFile_err != ''">{{ audioFile_err }}</p>
                                    </b-form-group> -->

                                    <!-- <Tag @updateTag="updateTagFromChild" :oldtags="tags" />
                                    <p class="err" v-if="tags_err != ''">{{ tags_err }}</p> -->

                                    <Tag @updateTag="updateTagFromChild" oldtags="" />
                                    <p class="err" v-if="tags_err != ''">{{ tags_err }}</p>


                                    <!-- <b-tabs>
                                        <b-tab title="Goals" active>
                                            <Goal @updateGoal="updateGoalFromChild" oldgoals="" />
                                        </b-tab>
                                        <b-tab title="Feelings">
                                            <Feeling @updateFeeling="updateFeelingFromChild" oldfeelings="" />
                                        </b-tab>
                                    </b-tabs> -->

                                    <!-- <p class="err" v-if="goal_err != ''">{{ goal_err }}</p>
                                    <p class="err" v-if="feeling_err != ''">{{ feeling_err }}</p> -->

                                    <!-- <b-form-group id="example-input-group-1" label="Text"
                                        label-for="example-input-1">
                                        <vue-editor v-model="form.text" />
                                        <p class="err" v-if="text_err != ''">{{ text_err }}</p>
                                    </b-form-group> -->

                                    <b-form-group id="example-input-group-1" label="Text" label-for="contentTextarea">
                                        <!-- <vue-editor v-model="form.text" /> -->
                                        <b-form-textarea @keydown.native="test_keydown_handler2" id="contentTextarea"
                                            name="contentTextarea" v-model="$v.form.text.$model"
                                            :state="$v.form.text.$dirty ? !$v.form.text.$error : null"
                                            aria-describedby="input-1-live-feedback" placeholder="Enter something..."
                                            rows="3" max-rows="6"></b-form-textarea>

                                        <div v-if="$v.form.text.$error" class="invalid-feedback">
                                            <div v-if="!$v.form.text.required">Text is required</div>
                                        </div>

                                        <!-- <p class="err" v-if="text_err != ''">{{ text_err }}</p> -->
                                    </b-form-group>

                                    <b-form-group id="example-input-group-1" label="Description"
                                        label-for="descriptionTextarea">
                                        <b-form-textarea  @keydown.native="test_keydown_handler" id="descriptionTextarea" name="example-input-1"
                                            v-model="$v.form.description.$model"
                                            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
                                            aria-describedby="input-1-live-feedback" placeholder="Enter something..."
                                            rows="3" max-rows="6"></b-form-textarea>

                                            <div v-if="$v.form.description.$error" class="invalid-feedback">
                                            <div v-if="!$v.form.description.required">Description is required</div>
                                        </div>
                                    </b-form-group>

                                    <b-button :disabled='is_loading' type="submit" variant="success"
                                        class="btn-block believe-btn">
                                        <i
                                            :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                        Submit
                                    </b-button>
                                </b-form>
                                <vue-snotify></vue-snotify>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required,maxLength } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
import Tag from "@/components/Tag.vue";
// import Goal from "@/components/Goal.vue";
// import Feeling from "@/components/Feeling.vue";
import AudioFile from "@/components/AudioFile.vue";
import { VueEditor } from "../../../../node_modules/vue2-editor";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioAddModal',
    mixins: [validationMixin],
    data() {
        return {
            pageNumber : "", // search &
            category_name : null,
            count: 0,
            msg: "",
            is_loading: false,
            image_error: "",
            image_url: "",
            image_error2: "",
            image_url2: "",
            audioFile: "",
            text_err: "",
            audioFile_err: "",
            tags_err: "",
            file_duration: "",
            tags: [],
            goal_err: "",
            feeling_err: "",
            goals: [],
            feelings: [],
            form: {
                text : null,
                name: null,
                image: "",
                path: null,
                description: null,
                cat_id: null,
                duration: null,
                cover_image: null,
                author: null,

            }
        }
    },

    components: {
        VueEditor,
        Tag,
        AudioFile,
        // Goal,
        // Feeling,
        // simpleSnotifysuccess
    },
    validations: {
        form: {
            name: {
                required,
                maxLength : maxLength(100)
            },
            description: {
                required,
            },
            text: {
                required,
            },
            author: {
                required,
                maxLength : maxLength(30)
            },
        }
    },
    watch: {
        tags(newVal) {
            if (newVal.length > 0) {
                this.tags_err = "";
            }
        }
        // ,goals(newVal) {
        //     if (newVal.length > 0) {
        //         this.goal_err = "";
        //     }
        // },
        // feelings(newVal) {
        //     if (newVal.length > 0) {
        //         this.feeling_err = "";
        //     }
        // }
        
    },

    mounted() {
        window.addEventListener("keypress", function (e) {
            if (e.code == "Enter") {
                e.preventDefault()
            }
        }.bind(this));
    },

    created() {
        this.items()
        // console.log('from created');
        // console.log(this.image_url);
    },

    methods: {

        test_keydown_handler2(event) {
            if (event.which === 13) {
                let txt = document.getElementById('contentTextarea');
                let indexOfChar = txt.value.slice(0, txt.selectionStart).length + 1
                txt.value = txt.value.slice(0,txt.selectionStart) + '\r\n' + 
                txt.value.slice(txt.selectionStart,txt.value.length);
                this.setCaretPosition('contentTextarea',indexOfChar);
            }
        },

        test_keydown_handler(event) {
            if (event.which === 13) {
                let txt = document.getElementById('descriptionTextarea');
                let indexOfChar = txt.value.slice(0, txt.selectionStart).length + 1
                txt.value = txt.value.slice(0, txt.selectionStart) + '\r\n' +
                txt.value.slice(txt.selectionStart, txt.value.length);
                this.setCaretPosition('descriptionTextarea',indexOfChar);
            }
        },

        setCaretPosition(elemId, caretPos) {
            var elem = document.getElementById(elemId);
            if (elem != null) {
                if (elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.move('character', caretPos);
                    range.select();
                }
                else {
                    if (elem.selectionStart) {
                        elem.focus();
                        elem.setSelectionRange(caretPos, caretPos);
                    }
                    else
                        elem.focus();
                }
            }
        },
        updateTagFromChild(arr) {
            this.tags = arr;
        },

        // updateGoalFromChild(arr) {
        //     this.goals = arr;
        // },
        // updateFeelingFromChild(arr) {
        //     this.feelings = arr;
        // },

        async items() {
            let data = await this.$store.getters['getEditFormData'];
            if (data.length == 0) {
                data = localStorage.getItem('data_for_edit');
                data = JSON.parse(data);
            }
            this.pageNumber = data?.pageNumber,
            // console.log("data data data :::::: " , data);
            this.form.text = data?.text.replaceAll('<br />','</p><p>');
            this.form.name = data?.name;
            this.form.description = data?.description;
            this.form.cat_id = data?.script_id;
            this.image_url = data?.image;
            this.form.author = data?.author;
            this.tags = data?.tag;
            // if(data?.goal != null)
            // {
            //     this.goals = data?.goal;
            // }
            // if(data?.feeling != null)
            // {
            //     this.feelings = data?.feeling;
            // }
            this.form.id = data?.id;
            this.category_name = data?.category_name
        },

        closeAudioPlayer() {
            this.audioFile = "";
            this.$refs.audioFile.value = null;
        },

        goBack() {
            const category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
            let prev_url = `/scripts/list/${category_id}`;
            this.$store.state.pageNumberAfterAction = this.pageNumber; // search &
            this.$router.push({ path: prev_url });
        },

        updateTagFromChild(arr) {
            this.tags = arr;
        },
        onAudioImageChange(e) {
            this.form.image = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image1')
            if (status) {
                this.image_error = "";
                this.form.image = file;
                this.image_url = URL.createObjectURL(file)
            }
        },
        onAudioImageChange2(e) {
            this.form.cover_image = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image2')
            if (status) {
                this.image_error2 = "";
                this.form.cover_image = file;
                this.image_url2 = URL.createObjectURL(file)
            }
        },

        checkFileType(file, num) {
            var types = ['image/jpeg', 'image/png', 'image/jpg']
            var msg = "The image must be a file of type: jpeg, jpg, png";
            if (num == 'audio') {
                types = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/mp3']
                if (!types.includes(file.type)) {
                    this.audioFile_err = "The audio must be a file of type: mp3, wav, ogg"
                    this.audioFile = ""
                    return false;
                }
                return true;
            } else {
                if (!types.includes(file.type)) {
                    if (num == 'image1') {
                        this.image_error = msg
                        this.form.image = "";
                    }
                    if (num == 'image2') {
                        this.image_error2 = msg
                        this.form.cover_image = "";
                    }
                    return false;
                }
                return true;
            }
        },

        uploadAudio(e) {
            this.audioFile_err = "";
            this.audioFile = "";
            const audio = e.target.files[0];
            var status = this.checkFileType(audio, 'audio');
            if (status) {
                const reader = new FileReader();
                reader.readAsDataURL(audio);
                var audio_el = document.createElement("audio");
                reader.onload = (e) => {
                    this.audioFile = e.target.result;
                    console.log('audio upload');
                    console.log(this.audioFile);
                    audio_el.src = e.target.result;
                    audio_el.addEventListener("loadedmetadata", () => {
                        var duration = audio_el.duration;
                        // const audio_file = new Date(duration * 1000).toISOString().slice(14, 19);
                        this.file_duration = duration;
                        // console.log(
                        //   "The duration of the song is of: " + this.file_duration + " seconds"
                        // );
                    });
                };
                this.count++;
                // console.log(this.file_duration, "Song Duration");
            }
            console.log('audio upload');
            console.log(this.audioFile);
        },

        async onSubmit() {
            // console.log("cccc ::::: " , this.tags);
            this.$v.form.$touch()

            if (this.$v.form.$anyError) {
                return false;
            }
            if (this.image_url != ""
            // && this.form.text != null
                // && this.image_url2 != ""
                // && this.audioFile != ""
                && this.tags.length != 0
                // && this.goals.length != 0
                // && this.feelings.length != 0
            ) {
                try {
                    
                    // if(this.form.text == "" || this.form.text == null){
                    //     this.text_err = "Text is required";
                    //     return false;
                    // }

                    this.is_loading = true;
                    // let obj = this.$store.getters['getEditFormData'];
                    let endpoint = "/admin/update-chapter";
                    const formData = new FormData();
                    formData.append("id", this.form?.id);
                    formData.append("name", this.form?.name);
                    formData.append("script_id", this.form?.cat_id);
                    formData.append("description", this.form.description);
                    const replaceText = this.form.text.replaceAll('</p><p>','<br />');
                    formData.append("text", replaceText);
                    // formData.append("duration", this.text_duration);
                    formData.append("author", this.form.author);
                    

                    if (typeof this.tags == 'string') {
                        formData.append('tag[]', this.tags);
                    } else {
                        for (var i = 0; i < this.tags.length; i++) {
                            formData.append('tag[]', this.tags[i]);
                        }
                    }

                    // if (typeof this.goals == 'string') {
                    //         formData.append('goal[]', this.goals);
                    //     } else {
                    //         // console.log('else')
                    //         for (var i = 0; i < this.goals.length; i++) {
                    //             formData.append('goal[]', this.goals[i]);
                    //         }
                    //     }

                    //     if (typeof this.feelings == 'string') {
                    //         formData.append('feeling[]', this.feelings);
                    //     } else {
                    //         // console.log('else')
                    //         for (var i = 0; i < this.feelings.length; i++) {
                    //             formData.append('feeling[]', this.feelings[i]);
                    //         }
                    //     }

                    if (this.form.image) {
                        formData.append("image", this.form.image);
                    }

                    let result = await api.post(endpoint, formData);
                    const msg = await result.data.message;
                    this.$store.dispatch('setMessage', { msg });
                    // await this.$store.dispatch(`scripts/all_after_submit`, {
                    //     id: this.form?.cat_id
                    // });
                    // this.$root.$refs.Audio.$refs['add-modal'].hide();
                    // const category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
                    let prev_url = `/scripts/list/${this.form?.cat_id}`;
                    this.$store.state.pageNumberAfterAction = this.pageNumber; // search &
                    this.$router.push({ path: prev_url });
                    this.is_loading = false;
                } catch (error) {
                    console.log(error);
                    // this.progress = 0;
                    // let err = error.response.data.message;
                    // this.$store.dispatch('setMessage', { err });
                    // this.showSnotifyError()
                    // this.$toast.error(this.error);
                }
            } else {
                // this.text_err = (this.form.text == null && this.text_err == "") ? "Text is required" : this.text_err;
                // this.audioFile_err = (this.audioFile == "" && this.audioFile_err == "") ? "Audio file is required" : this.audioFile_err;
                this.image_error = (this.image_url == "" && this.image_error == "") ? "Image is required" : this.image_error
                // this.image_error2 = (this.image_url2 == "" && this.image_error2 == "") ? "Image is required" : this.image_error2
                this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags required" : this.tags_err
                // this.feeling_err = (this.feelings.length == 0 && this.feeling_err == '') ? "Feelings require" : this.feeling_err
                // this.goal_err = (this.goals.length == 0 && this.goal_err == '') ? "Goals require" : this.goal_err
            }
        },


        // showSnotifySuccess(msg) {
        //     let obj = this.$store.getters['getMessage'];
        //     this.$snotify.success(obj.msg, {
        //         timeout: 5000,
        //         showProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //     });
        // },
        // showSnotifyError(msg) {
        //     let obj = this.$store.getters['getMessage'];
        //     this.$snotify.error(obj.msg, {
        //         timeout: 5000,
        //         showProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //     });
        // },

    },




}
</script>